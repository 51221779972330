import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const galleryHighQuality = [
	<StaticImage
		src='../images/gallery/1.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/2.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/3.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/4.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/5.jpg'
		imgStyle={{ objectPosition: 'top !important' }}
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/6.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/7.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/8.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/9.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/10.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/11.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/12.JPG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/13.JPG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/14.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/15.PNG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/16.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/17.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/18.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/19.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/20.JPG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/21.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/22.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/23.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/24.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/25.JPG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/26.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
	<StaticImage
		src='../images/gallery/27.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={100}
	/>,
]

export const galleryLowQuality = [
	<StaticImage
		src='../images/gallery/1.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/2.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/3.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/4.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/5.jpg'
		imgStyle={{ objectPosition: 'top !important' }}
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/6.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/7.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/8.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/9.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/10.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/11.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/12.JPG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/13.JPG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/14.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/15.PNG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/16.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/17.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/18.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/19.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/20.JPG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/21.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/22.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,

	<StaticImage
		src='../images/gallery/23.jpg'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/24.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/25.JPG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/26.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
	<StaticImage
		src='../images/gallery/27.JPEG'
		className='gallery-img-wrapper'
		imgClassName='gallery-img rounded-sm'
		alt='T&L Nails Gallery Photo'
		quality={5}
	/>,
]
